












































































































































































import Component, { mixins } from 'vue-class-component'
import _ from 'lodash'
import { PagedResults, QuizCurriculumInfo, QuizPackInfo, TableState } from '@/edshed-common/api/types'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'
import { Api, CurriculumProduct } from '@/edshed-common/api'
import ComponentHelperBase from '@/edshed-common/mixins/ComponentHelperBase'

@Component({})
export default class CurriculaView extends mixins(ComponentHelperBase, ModalMixin) {
  private refreshIndex = 1

  private CurriculumProduct = CurriculumProduct

  private tableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  private results: PagedResults<QuizCurriculumInfo> = {
    items: [],
    total: 0
  }

  private record: Partial<QuizCurriculumInfo> | null = null

  public mounted () {
    this.loadData()

    this.$watch(() => this.$route, () => this.loadData())
    this.$watch(() => this.$store.state.user, () => this.loadData())
  }

  private async loadData () {
    const pack_id = this.numberParam('pack_id', true)

    this.results = await Api.getCurricula({ pack_id }, this.tableState)
  }

  private onPageChange (page: number) {
    this.tableState.page = page

    this.loadData()
  }

  private onSortChange (col: string) {
    const { sort, dir } = this.tableState

    if (sort !== col) {
      this.tableState.sort = col
      this.tableState.dir = 'asc'
    } else {
      this.tableState.dir = dir === 'asc' ? 'desc' : 'asc'
    }

    this.loadData()
  }

  private onSearchChange () {
    this.loadData()
  }

  private addRow () {
    const { pack_id } = this.$route.params

    this.record = {
      pack_id: parseInt(pack_id, 10),
      name: '',
      description: '',
      locale: 'en_GB',
      product: CurriculumProduct[0],
      data: {

      }
    }
  }

  private onEditRow (row: QuizCurriculumInfo) {
    this.record = _.cloneDeep(row) as QuizCurriculumInfo

    if (this.record!.data === undefined) {
      this.record!.data = {}
    }

    if (this.record.data?.roots === undefined) {
      Object.assign(this.record.data, { roots: [''] })
    }

    if (this.record.data?.domains === undefined) {
      Object.assign(this.record.data, { domains: [''] })
    }
  }

  private onAddRootClicked () {
    if (this.record!.data === undefined) {
      this.record!.data = {}
    }
    if (this.record!.data?.roots === undefined) {
      Object.assign(this.record!.data, { roots: [''] })
    } else {
      this.record!.data.roots.push('')
    }

    this.refreshIndex++
  }

  private onAddDomainClicked () {
    if (this.record!.data === undefined) {
      this.record!.data = {}
    }
    if (this.record!.data.domains === undefined) {
      Object.assign(this.record!.data, { domains: [''] })
    } else {
      this.record!.data.domains.push('')
    }

    this.refreshIndex++
  }

  private async onDeleteRow (row: QuizCurriculumInfo) {
    try {
      if (await this.deleteConfirm(row)) {
        await Api.deleteCurriculum(row.id)

        this.loadData()
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        await this.alert({
          title: 'Error',
          message: err.message
        })
      }
    }
  }

  private onCloseEdit () {
    this.record = null
  }

  private async onSaveEdit () {
    const { id } = this.record!

    try {
      await Api.saveCurriculum(this.record as QuizCurriculumInfo)

      this.record = null
      this.loadData()
    } catch (err: unknown) {
      if (err instanceof Error) {
        await this.alert({
          title: 'Error',
          message: err.message
        })
      }
    }
  }

  private packSelected (id: number, obj: QuizPackInfo) {
    if (this.record) {
      this.record.pack_id = id
    }
  }
}
